import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { displayCurrency, roundToCents } from 'util/money';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { ReactComponent as HistoryQueryIcon } from 'assets/images/history-query-icon.svg';

const FundTable = ({ pools, fundTotal, allocate, allocations, fund, notEnoughFundsPoolIds }) => {
  const POOL_REFS = pools.map(() => useRef());

  const triggerOtherAllocations = (alreadyTriggeredPoolIndex) => {
    POOL_REFS.forEach((ref, index) => {
      if (alreadyTriggeredPoolIndex !== index) {
        const poolValue = Number(ref.current.state.numAsString);

        allocate(poolValue, pools[index].poolId);
      }
    });
  };

  return (
    <div className="funds-table-container">
      <table className="table funds-table">
        <thead className="text-secondary">
          <tr>
            <th className="funds-table__allocation">Investment Pool </th>
            <th className="text-right">Units</th>
            <th className="funds-table__value-per-units text-right">Value Per Unit ($)</th>
            <th className="text-right funds-table__total-value">
              Total {allocate ? 'Pool' : 'Fund'} Value ($)
            </th>
            {fund && (
              <th className="text-right funds-table__transactions-history">Transactions History</th>
            )}
            {allocate && <th className="text-right funds-table__grant-amount">Grant Amount ($)</th>}
          </tr>
        </thead>
        <tbody>
          {pools.map((pool, index) => (
            <>
              <tr key={pool.poolId}>
                <td>{pool.name}</td>
                <td className="text-right">{roundToCents(pool.units)}</td>
                <td className="text-right">{displayCurrency(pool.navPrice)}</td>
                <td className="text-right">{displayCurrency(pool.poolFundValue)}</td>
                {fund && (
                  <td className="text-right">
                    <Link to={{ pathname: `/fund/transactions/${fund.id}/${pool.poolId}` }}>
                      <HistoryQueryIcon className="history-query-icon mr-2" />
                    </Link>
                  </td>
                )}
                {allocate && (
                  <td>
                    <NumberFormat
                      ref={POOL_REFS[index]}
                      className={`mt-1 text-right ${
                        notEnoughFundsPoolIds && notEnoughFundsPoolIds.includes(pool.poolId)
                          ? 'invalid'
                          : ''
                      }`}
                      customInput={Form.Control}
                      thousandSeparator
                      decimalScale={2}
                      allowNegative={false}
                      defaultValue={allocations[pool.poolId]}
                      onValueChange={(values) => {
                        const val = values.floatValue || 0;
                        allocate(val, pool.poolId);
                        triggerOtherAllocations(index);
                      }}
                    />
                    {notEnoughFundsPoolIds && notEnoughFundsPoolIds.includes(pool.poolId) && (
                      <div className="error-message">Not enough funds in the pool</div>
                    )}
                  </td>
                )}
              </tr>
              {pool.pendingAmount > 0 && (
                <>
                  <tr className="in-progress">
                    <td>Grants in Progress</td>
                    <td />
                    <td />
                    <td className="text-right">{displayCurrency(-pool.pendingAmount)}</td>
                    {fund && <td />}
                    {allocate && <td />}
                  </tr>
                  <tr className="in-progress">
                    <td>Total Available</td>
                    <td />
                    <td />
                    <td className="text-right">
                      {displayCurrency(pool.poolFundValue - pool.pendingAmount)}
                    </td>
                    {fund && <td />}
                    {allocate && <td />}
                  </tr>
                </>
              )}
            </>
          ))}
          {!allocate && (
            <tr>
              <td className="text-extra-bold" colSpan={3}>
                Total avalable account funds
              </td>
              <td className="text-right text-extra-bold">
                {displayCurrency(fundTotal - (fund?.pendingGrantAmount ?? 0))}
              </td>
              {fund && <td />}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FundTable;
